exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktualnosci-[slug]-index-tsx": () => import("./../../../src/pages/aktualnosci/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-[slug]-index-tsx" */),
  "component---src-pages-aktualnosci-index-tsx": () => import("./../../../src/pages/aktualnosci/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-index-tsx" */),
  "component---src-pages-aktualnosci-kategoria-[slug]-index-tsx": () => import("./../../../src/pages/aktualnosci/kategoria/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-kategoria-[slug]-index-tsx" */),
  "component---src-pages-aktualnosci-kategoria-index-tsx": () => import("./../../../src/pages/aktualnosci/kategoria/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-kategoria-index-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-obszar-dzialania-tsx": () => import("./../../../src/pages/obszar-dzialania.tsx" /* webpackChunkName: "component---src-pages-obszar-dzialania-tsx" */),
  "component---src-pages-oferta-[slug]-[slug]-index-tsx": () => import("./../../../src/pages/oferta/[slug]/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-oferta-[slug]-[slug]-index-tsx" */),
  "component---src-pages-oferta-[slug]-index-tsx": () => import("./../../../src/pages/oferta/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-oferta-[slug]-index-tsx" */),
  "component---src-pages-oferta-index-tsx": () => import("./../../../src/pages/oferta/index.tsx" /* webpackChunkName: "component---src-pages-oferta-index-tsx" */),
  "component---src-pages-opinie-tsx": () => import("./../../../src/pages/opinie.tsx" /* webpackChunkName: "component---src-pages-opinie-tsx" */),
  "component---src-pages-praca-tsx": () => import("./../../../src/pages/praca.tsx" /* webpackChunkName: "component---src-pages-praca-tsx" */),
  "component---src-pages-realizacje-[slug]-index-tsx": () => import("./../../../src/pages/realizacje/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-[slug]-index-tsx" */),
  "component---src-pages-realizacje-index-tsx": () => import("./../../../src/pages/realizacje/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-index-tsx" */),
  "component---src-pages-realizacje-kategoria-[slug]-index-tsx": () => import("./../../../src/pages/realizacje/kategoria/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-kategoria-[slug]-index-tsx" */),
  "component---src-pages-realizacje-kategoria-index-tsx": () => import("./../../../src/pages/realizacje/kategoria/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-kategoria-index-tsx" */),
  "component---src-templates-aktualnosci-news-post-item-tsx": () => import("./../../../src/templates/aktualnosci/NewsPostItem.tsx" /* webpackChunkName: "component---src-templates-aktualnosci-news-post-item-tsx" */)
}

