import "./src/styles/global.css";

import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

import { RootLayout } from "./src/layouts/RootLayout";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <RootLayout>{element}</RootLayout>;
};
